import React, { useState } from "react"
import { Link } from "gatsby"

import "./Nav.scss"

import useScroll from "../hooks/useScroll"

import DeNovoLogo from "../assets/denovo-logo.inline.svg"

const Nav = () => {
  const [nav, setNav] = useState("closed")
  const [scrolled, setScrolled] = useState(false)

  const toggleNav = () => {
    setNav(nav === "closed" ? "open" : "closed")
  }

  const { scrollY, scrollDirection } = useScroll()

  // useLayoutEffect(() => {
  //   if (scrollDirection === "down" && scrollY > 100) {
  //     setScrolled(true)
  //   } else {
  //     setScrolled(false)
  //   }
  // }, [setScrolled, scrollDirection, scrollY])

  return (
    <nav id="site-nav" className={`${nav} ${scrolled ? "hidden" : "visible"}`}>
      <span id="nav-desktop">
        <div className="left">
          <Link to="/services" activeClassName="active">
            Services
          </Link>
          <Link to="/procedure-info" activeClassName="active">
            Procedure Info
          </Link>
        </div>
        <Link
          to="/"
          id="nav-logo"
          activeClassName="active"
          aria-label="De Novo Permanent Cosmetics | Home"
        >
          <DeNovoLogo alt="De Novo Permanent Cosmetics logo" />
        </Link>
        <div className="right">
          <Link to="/photo-gallery" activeClassName="active">
            Photo Gallery
          </Link>
          <Link to="/contact" activeClassName="active">
            Contact
          </Link>
        </div>
      </span>
      <span id="nav-mobile">
        <Link to="/" id="nav-logo" activeClassName="active">
          <DeNovoLogo alt="De Novo Permanent Cosmetics logo" />
        </Link>
        <div
          id="mobile-nav"
          onClick={toggleNav}
          onKeyPress={toggleNav}
          role="button"
          aria-label="toggle nav"
          tabIndex={-1}
          className={nav}
        >
          <div className="wrap">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id="nav-items" className={nav}>
          <div className="wrap">
            <Link
              to="/"
              activeClassName="active"
              aria-label="De Novo Permanent Cosmetics | Home"
            >
              Home
            </Link>
            <Link to="/services" activeClassName="active">
              Services
            </Link>
            <Link to="/procedure-info" activeClassName="active">
              Procedure Info
            </Link>
            <Link to="/photo-gallery" activeClassName="active">
              Photo Gallery
            </Link>
            <Link to="/contact" activeClassName="active">
              Contact
            </Link>
          </div>
        </div>
        <div
          className="close-area"
          onClick={toggleNav}
          onKeyPress={toggleNav}
          role="button"
          aria-label="toggle nav"
          tabIndex={-1}
        />
      </span>
    </nav>
  )
}

export default Nav
