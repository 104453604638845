/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import '../styles/all.scss'

import Nav from './Nav'
import Footer from './Footer'
import NotificationBar from './NotificationBar'

const Layout = ({ children }) => {
  return (
    <>
      <NotificationBar />
      <div className="layout-wrap" style={{position: 'relative'}} >
        <Nav />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
