import React from 'react'

import './NotificationBar.scss'

import consentForm from '../assets/DeNovo_COVID-19_Form.pdf'

const NotificationBar = () => {
  return (
    <section id="notification-bar">
      <h5 className="title">Important information regarding COVID-19</h5>
      <div className="message">
        At De Novo, we continue to follow the recommendations from health
        authorities and are implementing appropriate precautions to protect
        ourselves and ensure our clients' health and safety. This document
        contains important information about our decision (yours and mine) to
        resume in-person services in light of the COVID-19 public health crisis.
      </div>
      <a
        href={consentForm}
        className="button"
        aria-label="download consent form"
        tabIndex={0}
        target="_blank"
        rel="noopener noreferrer"
      >
        View Our Consent Form
      </a>
    </section>
  )
}

export default NotificationBar
