import React from 'react'
import { Link } from 'gatsby'

import './Footer.scss'

import DeNovoLogo from '../assets/denovo-logo.inline.svg'

const Footer = () => {
  return (
    <footer>
      <Link
        id="footer-logo"
        activeClassName="active"
        to="/"
        aria-label="De Novo Permanent Cosmetics | Home"
      >
        <DeNovoLogo alt="De Novo Permanent Cosmetics logo" />
      </Link>
      <div id="contact-details">
        <span>
          {' '}
          <a
            href="https://g.page/denovo-permanent-cosmetics"
            className="unstyled"
            target="_blank"
            rel="noopener noreferrer"
          >
            7703 NE 72nd Ave, Vancouver, WA
          </a>
        </span>
        <span>Located inside Club Green Meadows</span>
        <span>
          <i>phone:</i>{' '}
          <a href="tel: 3608969538" className="unstyled">
            360.896.9538
          </a>
        </span>
        <span>
          <i>fax:</i> 866.631.7174
        </span>
        <span>
          <i>hours:</i> Tues–Fri, 10am–6pm
        </span>
      </div>
    </footer>
  )
}

export default Footer
