/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 */

import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'

const useScroll = () => {
  let rect = () => {
    if (typeof window !== 'undefined') {
      document.body.getBoundingClientRect()
    }
  }

  const [scrollData, setScrollData] = useState({
    lastScrollTop: 0,
    scrollX: rect.left,
    scrollY: -rect.top,
    scrollDirection: null,
  })

  const listener = () => {
    rect = document.body.getBoundingClientRect()
    const lastScrollTop = scrollData.lastScrollTop
    setScrollData({
      lastScrollTop: -rect.top,
      scrollX: rect.left,
      scrollY: -rect.top,
      scrollDirection: lastScrollTop > -rect.top ? 'up' : 'down',
    })
  }

  const handler = throttle(listener, 100, { leading: false, trailing: true })

  useEffect(() => {
    window.addEventListener('scroll', handler)
    return () => {
      window.removeEventListener('scroll', handler)
    }
  })

  return {
    scrollX: scrollData.scrollX,
    scrollY: scrollData.scrollY,
    scrollDirection: scrollData.scrollDirection,
  }
}

export default useScroll
